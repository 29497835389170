<template>
  <router-view/>
</template>

<style>

</style>

<script>
export default {
  created() {
    if (sessionStorage.token!==undefined && sessionStorage.token!==''){
      this.$router.push('/main');
    }
  }
}
</script>
