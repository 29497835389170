import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: ''
    },
    mutations: {},
    actions: {
        incrementToken: ({commit}, data) => {
            commit('changeToken', data)
        }
    },
    modules: {},
    getters: {
        getToken(state) {
            return state.token
        }
    }
})
