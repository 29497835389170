import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuesax from 'vuesax'


import 'vuesax/dist/vuesax.css'
Vue.config.productionTip = false

Vue.use(Vuesax,{
  primary:'rgb(46,46,74)',
  success:'rgb(23, 201, 100)',
  danger:'rgb(242,19,93)',
  warning:'rgb(255,130,0)',
  dark:'#b5b5b5',
  gery:'#505050'
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

