import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import( '../loginPage/login.vue')
    },
    {
        path: '/main',
        name: 'Main',
        component: () => import( '../mainPage/mainPage.vue'),
        children:[
            {
                path: '/user',
                name: 'User',
                component: () => import( '../userPage/userPage')
            }
        ]
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router
